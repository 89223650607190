import Toastify from 'toastify-js';

/**
 * @description shows instant toast message
 * @param message {string} text content of the message
 * @param type {("info"|"success"|"error")}
 */
export default class ToastMessage {
  constructor(message, type) {
    const wordsCount = message.split(' ').length;
    const baseTime = 4000;
    const toastDuration = baseTime + wordsCount * 290;

    const toast = Toastify({
      text: message,
      duration: toastDuration,
      gravity: 'bottom',
      position: 'center',
      stopOnFocus: true,
      className: `toast toast--${type}`,
      offset: {y: 30},
      onClick: () => {
        toast.hideToast();
      }
    }).showToast();
  }
}
