import { isDevMode, translations } from '../../shared';
import ToastMessage from './toast-message';


export default class LikeButton {
  constructor() {
    document.addEventListener('click', (e) => {
      if (e.target.classList.contains('like-button--enabled')) {
        e.preventDefault();

        if (e.target.dataset.modalHash) {
          window.location.hash = e.target.dataset.modalHash;
        } else if (!e.target.classList.contains('like-button--initialized')) {
          new LikeButtonComponent(e.target);
        }
      }
    });
  }
}

class LikeButtonComponent {
  constructor(node) {
    this.node = node;
    node.classList.add('like-button--initialized');
    this.isRequestPending = false;
    this.campaignId = node.dataset.campaignId;
    this.isFavourite = node.classList.contains('like-button--active');
    this.addToFavouritesUrl = node.dataset.addUrl;
    this.removeFromFavouritesUrl = node.dataset.removeUrl;

    this.setupComponent();
  }

  async setupComponent() {
    this.isRequestPending = true;
    await this.toggleFavourite();
    this.isRequestPending = false;

    this.node.addEventListener('click', async () => {
      if (this.node.dataset.modalHash) {
        window.location.hash = this.node.dataset.modalHash;
      } else {
        if (!this.isRequestPending) {
          this.isRequestPending = true;
          await this.toggleFavourite();
          this.isRequestPending = false;
        }
      }
    });
  }

  async toggleFavourite() {
    try {
      !this.isFavourite ? this.markAsFavourite() : this.markAsNotFavourite();
      if (!this.isFavourite) {
        new ToastMessage(translations.get('addToFavoritesSuccess'), 'success');
      } else {
        new ToastMessage(translations.get('removeFromFavoritesSuccess'), 'warning');
      }

      const res = await fetch(!this.isFavourite ? this.addToFavouritesUrl : this.removeFromFavouritesUrl);
      if (res.ok) {
        // set favourite tracking variable only if response is successful
        this.isFavourite = !this.isFavourite;
        if (this.node.dataset.destroyOnDislike === 'true' && !this.isFavourite) {
          this.destroyCard();
        }
      } else {
        throw new Error(`Failed to add campaign to favourites with id: ${this.campaignId}`);
      }
    } catch (e) {
      this.isFavourite ? this.markAsFavourite() : this.markAsNotFavourite();
      new ToastMessage(window.translations.get(this.isFavourite ? 'removeFromFavouritesError' : 'addToFavouritesError'), 'error');
      isDevMode && console.error(e);
    }
  }

  getSameButtons() {
    return document.querySelectorAll(`.like-button[data-campaign-id='${this.campaignId}']`);
  }

  getLikesCountTargets() {
    return document.querySelectorAll(`[data-campaign-likes-id='${this.campaignId}']`);
  }

  markAsFavourite() {
    this.getSameButtons().forEach(sameButton => {
      sameButton.classList.add('like-button--active');
      sameButton.setAttribute('title', window.translations.get('removeFromFavourites'));
    });

    this.getLikesCountTargets().forEach(node => {
      let current = Number(node.dataset.campaignLikesCount);
      current++;

      node.dataset.campaignLikesCount = current;
      node.innerHTML = current;
    });
  }

  markAsNotFavourite() {
    this.getSameButtons().forEach(sameButton => {
      sameButton.classList.remove('like-button--active');
      sameButton.setAttribute('title', window.translations.get('addToFavourites'));
    });

    this.getLikesCountTargets().forEach(node => {
      let current = Number(node.dataset.campaignLikesCount);
      current--;

      node.dataset.campaignLikesCount = current;
      node.innerHTML = current;
    });
  }

  destroyCard() {
    const card = this.node.closest('.campaign-card');
    card.remove();
  }
}
