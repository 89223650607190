import intlTelInput from 'intl-tel-input';


export default class PhoneInput {
  constructor() {
    this.phoneInputs = document.querySelectorAll('.phone-input');
    this.phoneInputs.forEach(phoneInput => new PhoneInputComponent(phoneInput));
  }
}

class PhoneInputComponent {
  constructor(node) {
    this.nativeInput = node.querySelector('.phone-input__input');
    this.hiddenInput = node.querySelector('.phone-input__hidden');

    this.phoneInput = intlTelInput(this.nativeInput, {
      separateDialCode: true,
      initialCountry: node.dataset.defaultLocale,
      hiddenInput: node.dataset.name,
      preferredCountries: ['pl', 'gb', 'de', 'ua'],
      autoPlaceholder: 'off',
      utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.12/js/utils.min.js'
    });
  }
}
