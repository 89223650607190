import { createPopper } from '@popperjs/core';


export default class Tooltip {
  constructor() {
    this.activeTolltips = [];

    this.tooltips = document.querySelectorAll('.tooltip');
    this.tooltips.forEach(tooltip => {
      const triggerButtons = tooltip.querySelectorAll('.tooltip__trigger');
      const content = tooltip.querySelector('.tooltip__content');

      triggerButtons.forEach(button => {
        if (tooltip.classList.contains('tooltip--hover')) {
          button.addEventListener('mouseover', () => {
            this.createTooltip(button, content);
          });

          button.addEventListener('mouseleave', () => {
            this.destroyTooltips();
          });
        } else {
          button.addEventListener('click', () => {
            this.destroyTooltips();
            this.createTooltip(button, content);
          });
        }
      });
    });

    document.addEventListener('click', (e) => {
      if (e.target.closest('.tooltip') === null) {
        this.destroyTooltips();
      }
    });
  }

  createTooltip(button, content) {
    this.activeTolltips.push(createPopper(button, content, {
      modifiers: [
        {name: 'offset', options: {offset: [0, 8]}}
      ],
    }));
  }

  destroyTooltips() {
    this.activeTolltips.forEach(activeTooltip => activeTooltip.destroy());
  }
}
