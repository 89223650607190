export default class CookiesInfo {
  constructor() {
    this.cookiesInfo = document.querySelector('.cookies-info');
    if (this.cookiesInfo) {
      const cookiesAcceptButton = this.cookiesInfo.querySelector('.cookies-info__accept');
      cookiesAcceptButton.addEventListener('click', () => {
        this.dismissCookiesInfo();
      });
    }
  }

  dismissCookiesInfo() {
    this.cookiesInfo.classList.add('cookies-info--hidden');
    this.cookiesInfo.setAttribute('aria-hidden', 'true');
    document.cookie = `${this.cookiesInfo.dataset.cookieName}=true;path=/`;
  }
}
