export default class ActiveScrollMenu {
  constructor() {
    // for debugging, enable to show current scroll detector line
    this.showHelpers = false;
    this.detectOffset = 240;

    this.scrollSections = document.querySelectorAll('[data-active-scroll-section]');
    this.scrollControls = document.querySelectorAll('[data-active-scroll-control]');
    this.currentSection = null;

    this.showHelpers && this.drawHelpers();
    this.initListeners();
  }

  initListeners() {
    this.scrollControls.forEach(control => {
      control.addEventListener('click', () => {
        const section = document.querySelector(`[data-active-scroll-section="${control.dataset.activeScrollControl}"]`);
        window.scrollTo({
          top: section.offsetTop - this.detectOffset,
          behavior: 'smooth',
        });
      });
    });

    if (this.scrollSections.length > 0) {
      window.addEventListener('scroll', () => {
        this.showHelpers && this.drawHelpers();

        const section = this.getOverlappingSection();
        if (section) {
          if (!this.currentSection || this.currentSection.dataset.activeScrollSection !== section.dataset.activeScrollSection) {
            this.currentSection = section;
            this.toggleMenuItems(section);
          }
        }
      });
    }
  }

  toggleMenuItems(section) {
    this.scrollControls.forEach(control => {
      control.classList.remove(control.dataset.activeScrollClass);
      if (section.dataset.activeScrollSection === control.dataset.activeScrollControl) {
        control.classList.add(control.dataset.activeScrollClass);
      }
    });
  }

  getOverlappingSection() {
    for (const section of this.scrollSections) {
      const top = section.getBoundingClientRect().top - this.detectOffset + window.scrollY
      const bottom = section.getBoundingClientRect().top + section.offsetHeight - this.detectOffset + window.scrollY;
      if (window.scrollY >= top && window.scrollY <= bottom) {
        return section;
      }
    }

    return null;
  }

  drawHelpers() {
    this.scrollHelper = document.createElement('span');
    this.scrollHelper.setAttribute('style', 'display: block; position: fixed; top: 200px; left: 0; background: magenta; height: 1px; width: 100%; z-index: 99999999999;');
    document.body.appendChild(this.scrollHelper);
  }
}
