import ModalController from './modal-controller';
import { translations } from '../../shared';


export default class ConfirmModal {
  constructor() {
    document.addEventListener('click', ({target}) => {
      const trigger = target.closest('[data-confirm]');
      if (trigger && !trigger.hasAttribute('data-confirm-initialized')) {
        trigger.setAttribute('data-confirm-initialized', '');
        new ConfirmModalComponent(trigger);
      }
    });
  }
}

class ConfirmModalComponent {
  constructor(trigger) {
    this.trigger = trigger;
    this.modalId = trigger.dataset.confirm;
    this.modal = document.querySelector(`#${this.modalId}`);
    this.form = this.modal.querySelector('.confirm-modal__form');
    this.requireSystemConfirm = this.modal.classList.contains('confirm-modal--system-confirm');
    this.isInitialized = this.modal.classList.contains('confirm-modal--initialized');

    if (!this.isInitialized) {
      this.modal.classList.add('confirm-modal--initialized');
      if (this.requireSystemConfirm) {
        this.form.addEventListener('submit', (e) => {
          const confirmed = window.confirm(translations.get('additionalConfirmRequired'));
          !confirmed && e.preventDefault();
        });
      }
    }

    this.openModal();
    trigger.addEventListener('click', () => {
      this.openModal();
    });
  }

  openModal() {
    this.form.reset();
    this.form.querySelectorAll('.form-control--touched').forEach(control => {
      control.classList.remove('form-control--touched');
    });

    if (this.trigger.dataset.confirmAction) {
      this.form.setAttribute('action', this.trigger.dataset.confirmAction);
    }

    ModalController.showModal(this.modalId);
  }
}
