export default class SideMenu {
  constructor() {
    this.sideMenu = document.querySelector('.side-menu');
    if (this.sideMenu) {
      const currentItem = this.sideMenu.querySelector('.current:not(.side-menu__item)');
      if (currentItem) {
        const currentItemSubmenu = currentItem.closest('.side-menu__submenu');
        if (currentItemSubmenu !== null) {
          currentItemSubmenu.classList.add('active');
          const submenuContainer = currentItemSubmenu.closest('.side-menu__item--has-submenu');
          submenuContainer.classList.add('active', 'current');
        }
      }
    }
  }
}
