export default class FormControl {
  constructor() {
    this.controls = document.querySelectorAll('.form-control');
    this.controls.forEach(control => {
      const input = control.querySelector('.form-control__input');
      input.addEventListener('blur', () => {
        control.classList.add('form-control--touched');
      });

      if (control.classList.contains('form-control--has-error')) {
        input.addEventListener('input', () => {
          if (input.checkValidity()) {
            control.classList.remove('form-control--has-error');
          }
        });
      }
    });
  }
}
