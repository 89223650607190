import { isPwa } from '../../shared';


export default class PwaMessage {
  constructor() {
    this.pwaMessage = document.querySelector('.pwa-message');
    if (!this.pwaMessage) {
      return;
    }

    this.findElements();
    this.initListeners();
    this.initState();
  }

  findElements() {
    this.closeButton = this.pwaMessage.querySelector('.pwa-message__close');
  }

  initListeners() {
    this.closeButton.addEventListener('click', () => {
      this.dismissPwaMessage();
    });
  }

  initState() {
    !isPwa() && this.showBanner();
  }

  dismissPwaMessage() {
    document.cookie = `${this.pwaMessage.dataset.cookieName}=true;path=/`;
    this.hideBanner();
  }

  showBanner() {
    this.pwaMessage.classList.remove('pwa-message--hidden');
    this.pwaMessage.setAttribute('aria-hidden', 'false');
  }

  hideBanner() {
    this.pwaMessage.classList.add('pwa-message--hidden');
    this.pwaMessage.setAttribute('aria-hidden', 'true');
  }
}
