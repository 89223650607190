import IMask from 'imask';

export default class InputMask {
  constructor() {
    this.inputs = [
      ...document.querySelectorAll('[data-input-mask]'),
      ...document.querySelectorAll('[data-input-regexp-mask]')
    ];

    this.inputs.forEach(input => {
      const maskedInput = IMask(input, {
        mask: input.dataset.inputMask || new RegExp(input.dataset.inputRegexpMask)
      });

      input.dataset.unmaskedValue = maskedInput.unmaskedValue || '';
      input.addEventListener('input', () => {
        input.dataset.unmaskedValue = maskedInput.unmaskedValue || '';
      });
    });
  }
}
