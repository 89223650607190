export default class Collapse {
  constructor() {
    this.controls = document.querySelectorAll('[data-collapse-control]');
    this.controls.forEach(control => {
      control.addEventListener('click', () => {
        control.classList.contains('active') ? control.classList.remove('active') : control.classList.add('active');

        const targets = document.querySelectorAll(`[data-collapse-content=${control.dataset.collapseControl}]`);
        targets.forEach(target => {
          control.classList.contains('active') ? target.classList.add('active') : target.classList.remove('active');
        });
      });
    });
  }
}
