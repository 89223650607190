export default class TextareaInput {
  constructor() {
    this.inputs = document.querySelectorAll('.textarea');
    this.inputs.forEach(input => new TextareaInputComponent(input));
  }
}

class TextareaInputComponent {
  constructor(node) {
    this.nativeInput = node.querySelector('.textarea__native');
    if (this.nativeInput.hasAttribute('maxlength')) {
      this.charsLeft = node.querySelector('.textarea__chars-left__value');
      this.nativeInput.addEventListener('input', () => {
        this.charsLeft.innerHTML = Number(this.nativeInput.getAttribute('maxlength') - this.nativeInput.value.length);
      });
    }
  }
}
