import ToastMessage from '../toast-message';


export default class FileInput {
  constructor() {
    this.fileInputs = document.querySelectorAll('.file-input');
    this.fileInputs.forEach(container => {
      const nativeInput = container.querySelector(`input[type='file']`);
      const uploadButton = container.querySelector('.file-input__button');
      const filename = container.querySelector('.file-input__filename');

      if (container.dataset.accept !== undefined) {
        nativeInput.setAttribute('accept', container.dataset.accept.split(',').map(ext => `.${ext}`).join(','));
      }

      if (container.dataset.isImage === 'true') {
        const previewImage = container.querySelector('.file-input__preview');

        nativeInput.addEventListener('change', () => {
          const file = nativeInput.files.item(0);
          const fileReader = new FileReader();

          fileReader.addEventListener('load', (e) => {
            container.classList.remove('file-input--has-error');
            if (container.dataset.maxSize !== undefined) {
              if (file.size / 1000 > Number(container.dataset.maxSize)) {
                new ToastMessage(container.dataset.maxSizeErrorMsg, 'error');
                nativeInput.value = null;
                previewImage.setAttribute('src', container.dataset.imgSrc);
              } else {
                previewImage.setAttribute('src', e.currentTarget.result);
                filename.innerHTML = file.name;
              }
            }
          });

          fileReader.readAsDataURL(file);
        });
      }

      uploadButton.addEventListener('click', () => {
        nativeInput.click();
      });
    });
  }
}
