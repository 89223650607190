import Choices from 'choices.js';
import { translations } from '../../shared';


export default class Dropdown {
  constructor() {
    this.dropdowns = document.querySelectorAll('.dropdown');
    this.dropdowns.forEach(dropdown => {
      const select = dropdown.querySelector('.dropdown__select');
      const dropdownInput = dropdown.querySelector('.dropdown__input')
      const isMultiple = dropdown.dataset.multiple === 'true';

      const choices = new Choices(select, {
        itemSelectText: '',
        noChoicesText: translations.get('noResults'),
        noResultsText: translations.get('noResults'),
        searchEnabled: false,
        shouldSort: false,
        removeItemButton: isMultiple,
        position: 'bottom',
      });

      if ((isMultiple && choices.getValue().length > 0) || (!isMultiple && choices.getValue().value !== '')) {
        dropdown.classList.add('dropdown--has-value');
        dropdownInput.value = choices.getValue(true).toString();
      }

      dropdownInput.addEventListener('dropdownReset', () => {
        dropdownInput.value = '';
        dropdown.classList.remove('dropdown--has-value');
        choices.destroy();
        choices.init();
      });

      choices.passedElement.element.addEventListener('change', () => {
        dropdownInput.value = choices.getValue(true).toString();
        dropdownInput.dispatchEvent(new CustomEvent('input'));

        if ((isMultiple && choices.getValue().length > 0) || (!isMultiple && choices.getValue().value !== '')) {
          dropdown.classList.add('dropdown--has-value');
        } else {
          dropdown.classList.remove('dropdown--has-value');
        }
      });
    });
  }
}
