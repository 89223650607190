export default class MobileMenu {
  constructor() {
    this.isMenuOpen = false;
    this.mobileMenu = document.querySelector('.mobile-menu');
    this.menuHamburger = document.querySelector('.mobile-menu__toggle');
    this.navbar = document.querySelector('.navbar');

    if (this.mobileMenu && this.menuHamburger && this.navbar) {
      this.menuHamburger.addEventListener('click', () => {
        this.isMenuOpen ? this.hideMobileNav() : this.showMobileNav();
      });
    }
  }

  hideMobileNav() {
    this.isMenuOpen = false;
    this.mobileMenu.classList.remove('mobile-menu--active');
    this.mobileMenu.classList.add('mobile-menu--hidden');

    this.navbar.classList.remove('navbar--mobile-menu-open');
    document.body.classList.remove('overflow-hidden');
    this.menuHamburger.classList.remove('hamburger--active');
    this.mobileMenu.setAttribute('aria-hidden', 'true');
  }

  showMobileNav() {
    this.isMenuOpen = true;
    this.mobileMenu.classList.remove('mobile-menu--hidden');
    this.mobileMenu.classList.add('mobile-menu--active');

    this.navbar.classList.add('navbar--mobile-menu-open')
    document.body.classList.add('overflow-hidden');
    this.menuHamburger.classList.add('hamburger--active');
    this.mobileMenu.setAttribute('aria-hidden', 'false');
  }

}
