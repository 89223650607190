export default class TextInput {
  constructor() {
    this.textInputs = document.querySelectorAll('.text-input');
    this.textInputs.forEach(textInput => new TextInputComponent(textInput));
  }
}

class TextInputComponent {
  constructor(node) {
    this.nativeInput = node.querySelector('.text-input__native');

    if (node.classList.contains('text-input--currency')) {
      this.currencyInput = node.querySelector('.text-input__currency-input');
      if (this.nativeInput.value !== '') {
        this.currencyInput.value = Number(this.nativeInput.value) * 100;
      }

      this.nativeInput.addEventListener('input', () => {
        if (this.nativeInput.value !== '') {
          this.currencyInput.value = Number(this.nativeInput.value) * 100;
        } else {
          this.currencyInput.value = '';
        }

        this.currencyInput.dispatchEvent(new Event('input'));
      });
    }

    if (node.classList.contains('text-input--password')) {
      this.isPasswordVisible = false;

      this.passwordToggleButton = node.querySelector('.text-input__password-toggle');

      this.passwordToggleButton.addEventListener('click', () => {
        if (this.isPasswordVisible) {
          this.passwordToggleButton.classList.remove('text-input__password-toggle--active');
          this.nativeInput.setAttribute('type', 'password');
        } else {
          this.passwordToggleButton.classList.add('text-input__password-toggle--active');
          this.nativeInput.setAttribute('type', 'text');
        }

        this.isPasswordVisible = !this.isPasswordVisible;
      });
    }
  }
}
