export default class Navbar {
  constructor() {
    this.navbar = document.querySelector('.navbar');
    if (this.navbar) {
      this.initLanguagePicker();
      this.updateNavbar();
      window.addEventListener('scroll', () => {
        this.updateNavbar();
      });
    }
  }

  updateNavbar() {
    if (window.scrollY > -1) {
      this.navbar.classList.add('navbar--scroll');
    } else {
      this.navbar.classList.remove('navbar--scroll');
    }
  }

  initLanguagePicker() {
    const container = this.navbar.querySelector('.navbar__nav__actions__lang');
    const button = this.navbar.querySelector('.navbar__nav__actions__lang__button');
    button.addEventListener('click', () => {
      container.classList.toggle('navbar__nav__actions__lang--active');
    });

    document.addEventListener('click', (e) => {
      if (!e.target.closest('.navbar__nav__actions__lang')) {
        container.classList.remove('navbar__nav__actions__lang--active');
      }
    });
  }
}
