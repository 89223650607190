export let translations = window.translations;
export let isDevMode = !!window.isDevMode;
export let isAuthenticated = window.isAuthenticated;

export const hideElement = (element) => {
  element.classList.add('d-none');
}

export const showElement = (element) => {
  element.classList.remove('d-none');
}

export const getLocale = () => {
  return document.documentElement.lang;
}

export const getCSRF = () => {
  return document.querySelector(`meta[name='csrf-token']`).getAttribute('content');
}

export const isPwa = () => {
  return window.matchMedia('(display-mode: standalone)').matches;
}

export const breakpoints = {
  xl: 1200,
  lg: 992,
  md: 768,
  sm: 576,
  xs: 460,
}
