import CookiesInfo from './components/shared/cookies-info';
import FlashMessage from './components/shared/flash-message';
import Navbar from './components/shared/navbar';
import MobileMenu from './components/shared/mobile-menu';
import FormControl from './components/shared/form/form-control';
import TextInput from './components/shared/form/text-input';
import ModalController from './components/shared/modal-controller';
import LikeButton from './components/shared/like-button';
import ActiveScrollMenu from './components/shared/active-scroll-menu';
import Dropdown from './components/shared/dropdown';
import Collapse from './components/shared/collapse';
import FileInput from './components/shared/form/file-input';
import PhoneInput from './components/shared/form/phone.input';
import Popover from './components/shared/popover';
import SideMenu from './components/shared/side-menu';
import ConfirmModal from './components/shared/confirm-modal';
import TextareaInput from './components/shared/form/textarea-input';
import Tooltip from './components/shared/tooltip';
import InputMask from './components/shared/input-mask';
import PaymentPendingModal from './components/platform/payment-pending-modal';
import PwaMessage from './components/shared/pwa-message';


window.addEventListener('DOMContentLoaded', () => {
  new ModalController();
  new CookiesInfo();
  new PwaMessage();
  new FlashMessage();
  new Navbar();
  new MobileMenu();
  new FormControl();
  new TextInput();
  new TextareaInput();
  new InputMask();
  new FileInput();
  new PhoneInput();
  new Dropdown();
  new LikeButton();
  new ActiveScrollMenu();
  new SideMenu();
  new Collapse();
  new Popover();
  new Tooltip();
  new ConfirmModal();
  new PaymentPendingModal();
});
