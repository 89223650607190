import MicroModal from 'micromodal';


export default class ModalController {
  constructor() {
    this.hashModals = document.querySelectorAll('.modal[data-hash]');
    this.activeModal = null;
    this.modalConfig = {
      openClass: 'modal--active',
      closeTrigger: '',
      disableFocus: true,
      onShow: (modal) => {
        if (modal.dataset.hash) {
          window.location.hash = modal.dataset.hash;
          this.activeModal = modal;
        }
      },
      onClose: (modal) => {
        if (modal.dataset.hash) {
          window.location.hash = '_';
          this.activeModal = null;
        }
      }
    };

    MicroModal.init(this.modalConfig);

    this.checkWindowHash();
    window.addEventListener('hashchange', () => this.checkWindowHash());

    this.initCloseButtons();
  }

  checkWindowHash() {
    this.hashModals.forEach((modal) => {
      if (`#${modal.dataset.hash}` === window.location.hash) {
        if (!this.activeModal) {
          this.showModal(modal);
        } else if (this.activeModal.dataset.hash !== modal.dataset.hash) {
          this.closeModal(this.activeModal);
          this.showModal(modal);
        }
      }
    })
  }

  initCloseButtons() {
    this.closeButtons = document.querySelectorAll('[data-micromodal-close]');

    this.closeButtons.forEach(closeBtn => {
      closeBtn.addEventListener('touchend', (e) => {
        this.closeModal(closeBtn.closest('.modal'));
        e.preventDefault();
      });

      closeBtn.addEventListener('click', () => {
        this.closeModal(closeBtn.closest('.modal'));
      });
    });
  }

  showModal(modal) {
    MicroModal.show(modal.id, this.modalConfig);
  }

  closeModal(modal) {
    MicroModal.close(modal.id);
  }

  static showModal(modalId) {
    MicroModal.show(modalId, {
      openClass: 'modal--active',
      disableFocus: true,
    });
  }

  static closeModal(modalId) {
    MicroModal.close(modalId);
  }
}
