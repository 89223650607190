import { createPopper } from '@popperjs/core';


export default class Popover {
  constructor() {
    this.activeTolltips = [];

    this.popovers = document.querySelectorAll('.popover');
    this.popovers.forEach(popover => {
      const triggerButtons = popover.querySelectorAll('.popover__trigger');
      const dismissButtons = popover.querySelectorAll('.popover__dismiss');
      const content = popover.querySelector('.popover__content');

      dismissButtons.forEach(button => {
        button.addEventListener('click', () => {
          this.destroyTooltips();
        });
      });

      triggerButtons.forEach(button => {
        button.addEventListener('click', () => {
          this.destroyTooltips();
          this.activeTolltips.push(createPopper(button, content, {
            modifiers: [
              {name: 'offset', options: {offset: [8, 8]}}
            ],
          }));
        });
      });
    });

    document.addEventListener('click', (e) => {
      if (e.target.closest('.popover') === null) {
        this.destroyTooltips();
      }
    });
  }

  destroyTooltips() {
    this.activeTolltips.forEach(activeTooltip => activeTooltip.destroy());
  }
}
