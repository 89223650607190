import { hideElement, showElement } from '../../shared';


export default class PaymentPendingModal {
  constructor() {
    this.modal = document.querySelector('#payment-pending-modal');
    if (this.modal && window.location.hash === '#pending') {
      this.initModal();
    }
  }

  initModal() {
    this.statusApi = this.modal.dataset.statusApi;
    this.loader = this.modal.querySelector('.payment-status__loader');
    this.loaderCount = this.modal.querySelector('.payment-status__loader__count');
    this.timeoutMessage = this.modal.querySelector('.payment-status__timeout-message');

    this.CHECK_INTERVAL = 5;
    this.MAX_CHECKS = 3;
    this.counter = 0;
    this.tries = 0;

    this.loaderCount.innerHTML = this.CHECK_INTERVAL * this.MAX_CHECKS;

    this.checkInterval = setInterval(async () => {
      if (this.counter >= this.CHECK_INTERVAL * this.MAX_CHECKS) {
        clearInterval(this.checkInterval);
        showElement(this.timeoutMessage);
        hideElement(this.loader);
        return;
      }

      if (this.counter % this.CHECK_INTERVAL === 0) {
        const paymentStatus = await this.checkPaymentStatus();
        if (window.location.hash === '#pending') {
          switch (paymentStatus) {
            case 'success': {
              window.location.hash = 'success';
              clearInterval(this.checkInterval);
              break;
            }

            case 'failed': {
              window.location.hash = 'error';
              clearInterval(this.checkInterval);
              break;
            }
          }
        } else {
          // stop checking payment status if users closes modal
          clearInterval(this.checkInterval);
          return;
        }

        this.tries++;
      }

      this.loaderCount.innerHTML = this.CHECK_INTERVAL * this.MAX_CHECKS - this.counter;
      this.counter++;
    }, 1000);
  }

  async checkPaymentStatus() {
    try {
      const res = await fetch(this.statusApi);
      if (!res.ok) {
        throw new Error('Unable to fetch payment status');
      }

      const data = await res.json();
      return data.status;
    } catch (e) {
      return null;
    }
  }
}
